<!-- <p>search works!</p> -->
<div class="container-fuild drimp-light p-5 hero-box box">
    <div class="container justify-content align-center selected">
        <p class="text-center text-white lead-p">ค้นหาบทความ</p>
        <p class="text-center text-white">กรุณากรอกคคำที่คุณต้องการ เพื่อให้ระบบช่วยค้นหาบทความ</p>
    </div>
</div>
<div class="container">
    <div class="row">
        <p class="pt-4 pb-2"> 
            <img height="35" src="./assets/images/Line-left.png" alt=""> 
            <span class="txt-green"> ผลการค้นหา </span> <span class="txt-gold">3 บทความ </span>
        </p>
    </div>      
    <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="card w-100 my-2">
                <div class="card-body">
                    <h6 class="card-subtitle mb-2 txt-gold">#Property</h6>
                    <h5 class="card-title">งัดกลยุทธ์สู้ศึกอสังหาฯ ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครง...</h5>
                    <h4 class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                        ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</h4>
                </div>
            </div>
            <div class="card w-100 my-2">
                <div class="card-body">
                    <h6 class="card-subtitle mb-2 txt-gold">#Property</h6>
                    <h5 class="card-title">งัดกลยุทธ์สู้ศึกอสังหาฯ ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครง...</h5>
                    <h4 class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                        ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</h4>
                </div>
            </div>
            <div class="card w-100 my-2">
                <div class="card-body">
                    <h6 class="card-subtitle mb-2 txt-gold">#Property</h6>
                    <h5 class="card-title">งัดกลยุทธ์สู้ศึกอสังหาฯ ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครง...</h5>
                    <h4 class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                        ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</h4>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12 text-center">   
            <p class="text-center">Advertising</p>
            <img class="py-2 px-lg-0 px-md-3" src="./assets/images/ads/ads-3.png" alt="">
            <img class="py-2 px-lg-0 px-md-3" src="./assets/images/ads/ads-4.png" alt="">
        </div>
    </div>
</div>