import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// routing
import { PagesRoutingModule } from './pages-routing.module';
// module
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from './components/shared.module';

// Components

import { FooterComponent } from './footer/footer.component';
import { DetailsComponent } from './home/details/details.component';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PagesComponent } from './pages.component';
import { CategoriesComponent } from './home/categories/categories.component';


@NgModule({
  declarations: [
    HomeComponent,
    PagesComponent,
    NavigationComponent,
    CategoriesComponent,
    DetailsComponent,
    FooterComponent,
  ],
  imports: [
    SharedModule,
    PagesRoutingModule,
    FontAwesomeModule,
    CommonModule
  ]
})
export class PagesModule { }
