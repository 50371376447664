<div class="container-fuild" id="popular-blog">
    <div class="container py-4 pb-5">
        <h1 class="lead lead-block text-center my-3 pt-xl-4 pb-3">บทความยอดนิยม
            <hr class="line-cap mt-2" />
        </h1>
        <div class="row mx-auto justify-content-center">
            <div class="ml-auto col-md-5 float-start">
                <div class="card card-news">
                    <div class="content">
                        <img src="./assets/images/overlay.png" alt="" class="card-img-overlay2">
                        <img src="./assets/images/mock_news/news-04.png" class="card-img-top" alt="...">
                    </div>
                    <div class="card-body py-1">
                        <small>#Property </small>
                        <p class="card-title mt-2">งัดกลยุทธ์สู้ศึกอสังหาฯ
                            ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครงการ
                        </p>
                        <p class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์
                            ทรีนีตี้
                            ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</p>
                    </div>
                </div>
            </div>
            <div class="row col-md-6 float-end">
                <div class="card align-self-center p-0">
                    <div class="row g-0">
                        <div class="col-12">
                            <div class="col-lg-5 col-md-6 p-0 float-start">
                                <img src="./assets/images/mock_news/news-sm-01.png" class="img-fluid" alt="...">
                            </div>
                            <div class="col-lg-7 col-md-6 float-end">
                                <div class="card-body py-1">
                                    <small class="my-2">#Trends</small>
                                    <p class="card-caption m-0">โอ๊ต - ปราโมทย์ ปาทาน รับพรีเซนเตอร์ เบเยอร์ ส่งเพลง
                                        มารช์ฟาดเย็น พร้อมวลีเด็ด...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card align-self-center p-0">
                    <div class="row g-0">
                        <div class="col-12">
                            <div class="col-lg-5 col-md-6 p-0 float-start">
                                <img src="./assets/images/mock_news/news-sm-02.png" class="img-fluid" alt="...">
                            </div>
                            <div class="col-lg-7 col-md-6 float-end">
                                <div class="card-body py-1">
                                    <small class="my-2">#Trends</small>
                                    <p class="card-caption m-0">โอ๊ต - ปราโมทย์ ปาทาน รับพรีเซนเตอร์ เบเยอร์ ส่งเพลง
                                        มารช์ฟาดเย็น พร้อมวลีเด็ด...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card align-self-center p-0">
                    <div class="row g-0">
                        <div class="col-12">
                            <div class="col-lg-5 col-md-6 p-0 float-start">
                                <img src="./assets/images/mock_news/news-sm-03.png" class="img-fluid" alt="...">
                            </div>
                            <div class="col-lg-7 col-md-6 float-end">
                                <div class="card-body py-1">
                                    <small class="my-2">#Trends</small>
                                    <p class="card-caption m-0">โอ๊ต - ปราโมทย์ ปาทาน รับพรีเซนเตอร์ เบเยอร์ ส่งเพลง
                                        มารช์ฟาดเย็น พร้อมวลีเด็ด...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>