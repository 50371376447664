<div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="10000">
        <img src="./assets/images/Hero/Mock-01.png" class="d-block w-100 image-ele-slide" alt="...">
        <div class="carousel-caption d-block d-md-block p-xl-5 p-md-4">
          <img  src="./assets/images/logobrand/grandtrin.png" class="d-block" alt="..." height="70">
          <p class="text-left pt-2 m-0 slide-title">แกรนด์ ทรีนีตี้ </p>
          <p class="slide-caption">งัดกลยุทธ์สู้ศึกอสังหาฯ ย้ำจุดยืนชัด คุณภาพ ความคุ้มค่าเหมาะสม ตั้งเป้ารายได้โต 20% 
            พร้อมเปิดอีก 2 โครงการ...</p>
          <button type="button" class="btn btn-outline-secondary btn-slide-more">อ่านต่อทั้งหมด</button>
        </div>
      </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>