<div class="container py-4 mb-5">

    <h1 class="lead-block text-center my-3 pt-xl-4 pb-4 mb-4 align-center">
        <img width="18" src="./assets/images/Line-left.png" alt="">
        บทสัมภาษณ์ - ไอเดีย 
        <!-- <hr class="line-cap mt-2"/> -->
    </h1>
    
    <div class="row">
        <div class="col-12">
            <div class="card-group">
                <div class="card card-news">
                    <img src="./assets/images/mock_news/news-01.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Property </small>
                        <h5 class="card-title mb-3 mt-2">งัดกลยุทธ์สู้ศึกอสังหาฯ
                            ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครงการ
                        </h5>
                        <p class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                            ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</p>
                    </div>
                 
                </div>
                <div class="card card-news">
                    <img src="./assets/images/mock_news/news-02.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Social</small>
                        <h5 class="card-title mb-3 mt-2">สวยตราตรึง! นุ่น วรนุช สุดเจิดจรัส ขึ้นปกนิตยสารแพรวเวดดิ้ง
                        </h5>
                        <p class="card-text">ถ้าถามว่าชุดไทยดาราคนไหนใส่แล้วดูสวยสง่างามมากที่สุด
                            หลายคนคงพุ่งเป้าไปที่สาว นุ่น วรนุช ภิรมย์ภักดี อย่างแน่นอน ล่าสุดสาวนุ่นยอม...</p>
                    </div>
                  
                </div>
                <div class="card card-news">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <app-ads></app-ads>
    </div>
</div>