<div class="container-fuild drimp-gray">
    <div class="container">
        <div class="col-lg-10 col-md-12 col-12 mx-auto justify-contain-center py-4">
            <div class="reated-lead text-left my-3 pt-xl-4 pb-3">
                <div class="float-start">
                    <img height="35" src="./assets/images/Line-left.png" alt="">
                    <span class="text-title"> บทความที่เกี่ยวข้อง</span>
                </div>
                <!-- <span class="float-end">ดูทั้งหมด</span> -->
            </div>

        </div>
        <div class="col-lg-10 col-md-12 col-12 mx-auto justify-contain-center pb-4">
            <div class="card-group mt-3 p-md-0">
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-01.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small class="txt-gold">#Property </small>
                        <h5 class="card-title mb-3 mt-2">งัดกลยุทธ์สู้ศึกอสังหาฯ
                            ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครงการ
                        </h5>
                        <p class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                            ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-02.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small class="txt-gold">#Social</small>
                        <h5 class="card-title mb-3 mt-2">สวยตราตรึง! นุ่น วรนุช สุดเจิดจรัส ขึ้นปกนิตยสารแพรวเวดดิ้ง
                        </h5>
                        <p class="card-text">ถ้าถามว่าชุดไทยดาราคนไหนใส่แล้วดูสวยสง่างามมากที่สุด
                            หลายคนคงพุ่งเป้าไปที่สาว นุ่น วรนุช ภิรมย์ภักดี อย่างแน่นอน ล่าสุดสาวนุ่นยอม...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small class="txt-gold">#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>

                <div class="card card-news">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small class="txt-gold">#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>