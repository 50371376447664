import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './home/details/details.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './home/search/search.component';
import { PagesComponent } from './pages.component';
import { CategoriesComponent } from './home/categories/categories.component';

const Pagesroutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'} ,
  {
    path: 'home', component: PagesComponent, // This component defines the shared main content around a router outlet.
    children: [
      { path: '', component: HomeComponent },    // This component is a main page
      { path: 'details', component: DetailsComponent },
      { path: 'categories', component: CategoriesComponent },
      { path: 'search', component: SearchComponent },
    ]
  },
  { path: '**', redirectTo: '/home', pathMatch: 'full'} ,
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(Pagesroutes),
    CommonModule
  ],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
