
    <div class="container-fluid" id="menu-block" >
        <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white" style="  border-top: 5px solid #C89A7E; ">
            <div class="container">
              <!-- <a class="navbar-brand" href="#">Navbar</a> -->
              <a class="navbar-brand p-0" href="#">
                <img src="./assets/images/logo_v3.png" alt="" width="125" height="">
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link"  routerLink="/home" routerLinkActive="active">หน้าหลัก</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">ข่าว</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">ไลฟ์สไตล์</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">บทสัมภาษณ์/ไอเดีย</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">คอลัมนิสต์</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">การอัปเดตเทรนด์</a>
                  </li>
  
               
                </ul>
                <button class="py-auto px-3 search-nav" (click)="gosearch()"><i class="bi bi-search"></i></button>
             
                <button class="btn btn-secondary py-1 px-3 ms-2" type="submit">ติดต่อเรา</button>
              </div>
            </div>
          </nav>
    </div>
