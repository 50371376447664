import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor(
    private activeRouter: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const activeChild = this.activeRouter.children.length;
    console.log(activeChild);
    if (activeChild === 0) {
        // your active children 1 or more than children then active 1,otherwise it is 0

    }
  }

}
