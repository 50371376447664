<div class="container-fluid pb-0 mb-0 justify-content-center text-light footer-area ">
    <footer>
        <div class="row mt-5 justify-content-center py-5">
            <div class="col-11">
                <div class="row ">
                    <div class="col-xl-6 col-md-3 col-sm-3 col-12 ">
                        <div class="text-muted mb-md-3 bold-text">
                            <img  class="footer-img" src="./assets/images/logo_v3-1.png" alt="" width="" height="">
                            <p class="my-2">www.infinite-bkk.com</p>
                        </div>
                        <div class="col-12">
                          
                            <p class="mb-1">168/8 ถ.ประเสริฐมนูกิจ คลองกุ่ม บึงกุ่ม</p>
                            <p>กรุงเทพมหานคร 10240</p>
                        </div>
                    </div>

                    <div class="col-xl-2 col-md-3 col-sm-3 col-12">
                        <ul class="list-unstyled">
                            <li class="bold-mid-text">หน้าหลัก</li>
                            <li class="bold-mid-text">บทสัมภาษณ์ / ไอเดีย</li>
                            <li class="bold-mid-text">คอลัมนิสต์</li>
                            <li class="bold-mid-text">อัปเดตเทรนด์</li>
                            <li class="bold-mid-text">ติดต่อเรา</li>
                        </ul>
                    </div>

                    <div class="col-xl-2 col-md-3 col-sm-3 col-12">
                        <ul class="list-unstyled">
                            <li class="bold-text">ข่าว</li>
                            <li>ธุรกิจ / สินค้าอุปโภคบริโภค</li>
                            <li>ยานยนต์</li>
                            <li>นวัตกรรม / ไอที</li>
                            <li>ทรัพย์สิน</li>
                            <li>การเงิน / การลงทุน</li>
                            <li>สังคม</li>
                            <li>ข่าวทั้งหมด</li>
                        </ul>
                    </div>

                    <div class="col-xl-2 col-md-3 col-sm-3 col-12">
                        <ul class="list-unstyled">
                            <li class="bold-text">ไลฟ์สไตล์</li>
                            <li>การท่องเที่ยว</li>
                            <li>อาหาร</li>
                            <li>แฟชั่น</li>
                            <li>โปรโมชั่น</li>
                        </ul>
                    </div>

            
                </div>
                <!-- <div class="row ">
                    <div class="col-xl-8 col-md-4 col-sm-4 col-auto my-md-0 mt-5 order-sm-1 order-3 align-self-end">
                        <p class="social text-muted mb-0 pb-0 bold-text"> <span class="mx-2"><i class="fa fa-facebook" aria-hidden="true"></i></span> <span class="mx-2"><i class="fa fa-linkedin-square" aria-hidden="true"></i></span> <span class="mx-2"><i class="fa fa-twitter" aria-hidden="true"></i></span> <span class="mx-2"><i class="fa fa-instagram" aria-hidden="true"></i></span> </p><small class="rights"><span>&#174;</span> Pepper All Rights Reserved.</small>
                    </div>
                    <div class="col-xl-2 col-md-4 col-sm-4 col-auto order-1 align-self-end ">
                        <h6 class="mt-55 mt-2 text-muted bold-text"><b>ANIRUDH SINGLA</b></h6><small> <span><i class="fa fa-envelope" aria-hidden="true"></i></span> anirudh@gmail.com</small>
                    </div>
                    <div class="col-xl-2 col-md-4 col-sm-4 col-auto order-2 align-self-end mt-3 ">
                        <h6 class="text-muted bold-text"><b>RISHABH SHEKHAR</b></h6><small><span><i class="fa fa-envelope" aria-hidden="true"></i></span> rishab@gmail.com</small>
                    </div>
                </div> -->
            </div>
        </div>

    </footer>

</div>
<div class="container-fluid drimp p-0 bold-mid-text">
    <div class="m-0 text-white px-5 py-3 col-6">© 2021 Mockup --devnate1a. All Rights Reserved.</div>
    <!-- <div class="m-0 text-white px-5 py-3 col-6"> dd</div> -->
</div>