<div class="container-fuild">

    <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
                <div class="content">
                    <img src="./assets/images/overlay.png" alt="" class="card-img-overlay2">
                    <img src="./assets/images/mock_news/news-01.png" class="w-100 text-overlay" alt="...">
                </div>
                <div class="carousel-caption text-overlay col-lg-8 px-lg-5 px-sm-3 mx-auto">
                    <div class="line">
                        <img src="./assets/images/Line-left.png" alt="">
                    </div>
                    <div>
                        <h1 class="pt-2 m-0 text-white text-overlay lead-shadow">แกรนด์ ทรีนีตี้ </h1>
                        <h2 class="slide-caption text-overlay lead-shadow">อสังหาฯ พร้อมเปิดอีก 2 โครงการ </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <div class="col-lg-8 col-md-10 col-12 mx-auto justify-contain-center mt-4 pb-4">
            <nav aria-label="breadcrumb mb-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">
                            <fa-icon [icon]="faHome"></fa-icon> หน้าหลัก
                        </a></li>
                    <li class="breadcrumb-item"><a href="/categories">ไลฟ์สไตล์</a></li>
                    <li class="breadcrumb-item active" aria-current="page"> แกรนด์ ทรีนีตี้ งัดกลยุทธ์สู้ศึกอสังหาฯ ...
                    </li>
                </ol>
            </nav>
            <h3 class="text-title mt-4">
                แกรนด์ ทรีนีตี้ งัดกลยุทธ์สู้ศึกอสังหาฯ ย้ำจุด ยืนชัด คุณภาพ ความคุ้มค่าเหมาะสม ตั้งเป้ารายได้โต 20%
                พร้อมเปิดอีก 2 โครงการ
            </h3>

            <p class="caption-break caption-break-1">
                แกรนด์ ทรีนี้ตี้ เตรียมเปิดอีก 2 โครงการ เน้นการวิเคราะห์พฤติกรรมผู้บริโภคเชิงลึก มั่นใจ Work
                Hard จะช่วยให้พร้อมชน แบรนด์ใหญ่ ไม่หวั่น Brand Royalty สู้ด้วยคุณภาพ และความคุ้มค่า
                เหมาะสม มองตลาดภาคเหนือศักยภาพสูง เร่งพัฒนารองรับกำลังซื้อแพทย์ นักธุรกิจ คนรุ่นใหม่
                หวังยอดรายได้โตทั้งปี 20 %
            </p>

            <p class="caption-break caption-break-2">
                <span class="caption-bold"> นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                    ดีเวลลอปเม้นท์
                    จำกัด กล่าวว่า
                </span> “ตลาดอสังหาริมทรัพย์ยังคงได้รับผลกระทบจากการชะลอตัวของเศรษฐกิจ
                เหตุผลมาจากทั้งโรคระบาดโควิด 19 และการชะลอตัวของภาพรวมเศรษฐกิจโลก ทำให้การ
                เติบโตในธุรกิจอสังหาในช่วง 2-3 ปีนี้อาจจะต้องเป็นไปอย่างไม่ราบรื่นนัก โดยแกรนด์ ทรีนีตี้เริ่ม
                ขยายธุรกิจตั้งแต่ช่วง Q3 ในปีที่ผ่านมา เปิดโครงการบ้านเดี่ยว Luxury Cluster Housing 3
                โครงการ มูลค่ารวม 480 ล้านบาท ทำเลพหลโยธิน, สุคนธสวัสดิ์ และ สตรีวิทยา ทั้งหมด 18 ยูนิต
                สุดเอ็กซ์คลูซีฟ อีกทั้งยังได้ผลตอบรับที่ดีโดยเฉพาะโครงการ วัน แอททีเลียร์ ไพรเวท เรสซิเดนซ์
                พหลฯ โดยเปิด Sale Gallery ที่เมเจอร์ฯ รัชโยธิน (บริเวณชั้น 2 ทางเชื่อมBTS) ปัจจุบันทั้ง 3
                โครงการมียอดขายราว 20% แม้ประเทศไทยกำลังประสบกับปัญหาเศรษฐกิจชะลอตัว
                และสถานการณ์โรคระบาดโควิด 19 ระลอกใหม่ ทั้งนี้คาดการณ์ว่าจะปิดการขายภายใน Q2 ปี
                2022”
            </p>

            <p class="caption-break text-center caption-break-3">
                <img class=" w-100" src="./assets/images/mock_news/details/details-01.png" alt="">
                <span class="cap-text w-100 txt-green mt-4">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท
                    แกรนด์ ทรีนีตี้ ดีเวลลอปเม้นท์ จำกัด</span>
            </p>

            <p class="caption-break caption-break-3">
                <span class="caption-bold"> ภาพรวมธุรกิจของ แกรนด์ ทรีนีตี้ ในปี 2021
                </span> ยังคงเป็นในเรื่องของการพัฒนาโครงการบ้านแนวราบทั้งในพื้นที่กรุงเทพฯ
                และต่างจังหวัดตามแผนงานที่ได้วางไว้
                อาจจะมีการเปลี่ยนแปลงในเรื่องของการเปิดตัวบางโครงการออกไปในระยะเวลาสั้น ๆ บ้าง โดยช่วงปี 2021
                จะมีการเปิดตัวโครงการใหม่ 2 โครงการ เป็นโครงการบ้านแนวราบ ทั้งบ้านเดี่ยว
                และทาวน์โฮม ราคาขายเริ่มต้น 2.9-4.5 ล้านบาท มูลค่าโครงการรวมอยู่ที่ประมาณ 750 ล้านบาท
                และจะสามารถทยอยเปิดให้ชมบ้านตัวอย่างได้ช่วงไตรมาสที่ 3-4 ปีนี้
                บริษัทฯ คาดว่ายอดขายจะเริ่มเข้ามามากขึ้นในช่วงไตรมาสที่ 4 พร้อมทั้งตั้งเป้าอัตราการเติบโตยอดขายราว 20%
                พร้อมกันนี้จะได้พบกับบ้านตัวอย่างโครงการ วัน แอททีเลียร์ ไพรเวท เรสซิเดนซ์ พหลโยธิน Luxury Cluster Home
                4.5 ชั้น
                การออกแบบสไตล์ Model Classic และโครงการ วัน แอททีเลียร์ สตรีวิทยา บ้านแฝด 3 ชั้น New Modern Luxury
            </p>

            <p class="caption-break caption-break-4">
                <span class="caption-bold"> อีกทำเลธุรกิจที่โดดเด่นมากของ แกรนด์ ทรีนีตี้ คือทำเลภาคเหนือ
                </span> เรามองว่ายังเป็นทำเลที่พร้อมสำหรับการพัฒนา กลุ่มลูกค้ามีความต้องการบ้านคุณภาพ ดีไซน์สวย
                อยู่อาศัยได้อย่างลงตัวและสะดวกสบายต่อการเดินทาง บริษัทมีพื้นที่เตรียมพัฒนาในจังหวัดน่าน
                โดยแบ่งพัฒนาไปตามแผนงานบริษัท สำหรับโครงการบ้านเดี่ยว 2 ชั้น (CITY CITE NAN) บ้านเดี่ยวหรูสไตล์ Nordic
                ทำเลที่ดีที่สุด ใจกลางจังหวัดน่าน ติดถนนใหญ่ ให้คุณได้สัมผัส “สุขมากกว่า กับชีวิตที่ลงตัว” มูลค่าโครงการ
                237 ล้านบาท พื้นที่โครงการ ประมาณ 14 ไร่ ในอำเภอเมืองน่าน จังหวัดน่าน จำนวน 57 ยูนิต
                พื้นที่ใช้สอยเริ่มต้น 170-190 ตารางเมตร พื้นที่ดิน 50-100 ตารางวา
                และด้วยแนวคิดในการพัฒนาโครงการที่เน้นคุณภาพของบ้าน และดีไซน์ที่สวย เรียบโก้
                เพื่อการอยู่อาศัยที่ลงตัวพร้อมจับกลุ่มลูกค้านักธุรกิจ แพทย์ ข้าราชการ
            </p>

            <p class="caption-break text-center caption-break-5">
                <img class=" w-50 p-2" src="./assets/images/mock_news/details/details-03.png" alt="">
                <img class=" w-50 p-2" src="./assets/images/mock_news/details/details-04.png" alt="">
            </p>

            <p class="caption-break caption-break-6">
                ทิศทางในการทำงาน และแผนพัฒนาโครงการในช่วงนี้เชื่อว่าการคัดสรรที่ดินในทำเลที่ตั้งศักยภาพสูง
                คุ้มค่ามาพัฒนาโครงการ
                และการสำรวจความต้องการผู้บริโภคเพื่อออกแบบฟังก์ชันบ้านให้ตอบโจทย์ผู้อยู่อาศัยยุคใหม่อยู่เสมอ
                ซึ่งเป็นจุดแข็งของบริษัทฯอยู่แล้ว อีกทั้งสามารถช่วยให้โครงการของเรามีคุณค่าทั้งในเชิงความรู้สึก
                และมูลค่าของทรัพย์สินซึ่งถือเป็นเรื่องสำคัญของการพิจารณาซื้อบ้านกับทางบริษัทฯ โดยยังคงเน้นตลาดแนวราบ
                จับกลุ่ม Real demand ทั้งระดับ Luxury และ Economy ด้วยกลยุทธ์ การจัดวางฟังก์ชันและราคาที่คุ้มค่าที่สุด
                ตอบโจทย์ผู้อยู่อาศัยในปัจจุบันและอนาคต
            </p>

            <p class="caption-break caption-break-7">
                <span class="caption-bold"> “ ในช่วงวิกฤติแบบนี้ ผู้ประกอบการทุกรายต่างมุ่งพัฒนาสินค้าให้ดีที่สุด
                    และงัดกลยุทธทุกอย่างออกมาแข่งขันกัน
                </span> แต่เชื่อว่าการทำการบ้านมากพอหรือ Work Hard ยังคงใช้ได้อยู่ทุกยุค สำหรับ แกรนด์ ทรีนีตี้
                เรามีการทำวิจัยพฤติกรรมลูกค้า ศึกษาเชิงลึกในรายละเอียดและพบว่ากลุ่มลูกค้าคนรุ่นใหม่ในยุคปัจจุบันนี้ (Gen
                Y , Gen Z) ไม่มีพฤติกรรมประเภท Brand loyalty ที่ให้ความสำคัญกับแบรนด์สินค้ามากกว่าคุณภาพ
                จะให้ความสำคัญกับตัวสินค้ามากกว่า เน้นตัดสินใจเลือกสิ่งที่คุ้มค่าและเหมาะสมที่สุด
                อีกทั้งท่ามกลางวิถีชีวิตในปัจจุบันที่เร่งรีบ และต้องแข่งขันกับเวลา
                ซึ่งในยุคปัจจุบันที่สามารถเข้าถึงข้อมูลเชิงเปรียบเทียบ (รีวิว) ได้อย่างง่ายดาย ยิ่งทำให้ทุก ๆ
                ดีเวลลอปเปอร์ที่ทำการบ้านมากพอ มีโอกาสที่จะเข้าถึงกลุ่มลูกค้าได้เทียบเท่ากันหมด และงานหลักของ แกรนด์
                ทรีนีตี้ คือทำการบ้านอย่างหนักเพื่อสร้างบ้านที่สามารถตอบโจทย์กลุ่มลูกค้าในยุคปัจจุบัน ให้ได้มากที่สุด
                ไม่ว่าจะเป็นในแง่ความสะดวก หรือความคุ้มค่า” นายดิษย์ฐา จิราศิริวรภัทร กล่าวเสริม
            </p>

            <p class="caption-break caption-break-8">
                <span class="caption-bold"> “เราเชื่อว่าในปี 2021 หรืออีก 2-3 ปีข้างหน้าต่อจากนี้
                    เรื่องที่ส่งผลกระทบอย่างมาก และหลีกเลี่ยงไม่ได้ ก็คือโรคระบาด COVID-19
                    ที่ยังคงเป็นปัจจัยด้านลบที่สำคัญ
                </span>ส่งผลต่อทุกอุตสาหกรรม
                และจะส่งผลต่อกำลังซื้อที่อยู่อาศัยอย่างมากเนื่องจากการเดินทางระหว่างประเทศทั่วโลกถูกหยุดลง
                ส่วนอีกเรื่องคือการโยกย้ายฐานผลิตของอุตสาหกรรมหลัก ๆ
                ไปประเทศเพื่อนบ้านเนื่องจากปัจจัยส่งเสริมด้านการค้าภายในประเทศ โดยอุปสรรคทั้ง 2 เรื่องนี้
                น่าจะเป็นเรื่องหลัก ๆ ที่ส่งผลต่อการขยายตัวของเศรษฐกิจประเทศไทยในช่วง 1-5 ปี หรืออาจเป็น 10
                ปีต่อจากนี้อย่างแน่นอน หากยังไม่มีปัจจัยบวกอื่น ๆ เข้ามาเสริม”
            </p>

            <div class="caption-break caption-break-9 drimp-light">
                <div class="card mx-auto trans" style="max-width: 540px;">
                    <div class="row g-0">
                        <div class="col-md-5 just-cap">
                            <img class="img-fluid" src="./assets/images/mock_news/details/details-05.png" alt="...">
                        </div>
                        <div class="col-md-7 just-cap">
                            <div class="card-body">
                                <h5 class="card-title">นายดิษย์ฐา จิราศิริวรภัทร</h5>
                                <p class="card-text">ประธานเจ้าหน้าที่บริหาร <br>
                                    บริษัท แกรนด์ ทรีนีตี้ ดีเวลลอปเม้นท์ จำกัด</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="caption-break caption-break-10 drimp-light-xs">
                <p class="p-3 txt-green">
                    “แกรนด์ ทรีนีตี้ นอกจากการ Work Smart แล้ว ยังคงมุ่งมั่นที่จะ Work Hard
                    เพราะเชื่อว่าการที่เราใส่ใจ และให้ความสำคัญกับสิ่งที่เราต้องการจะส่งมอบไปให้กับลูกค้า
                    ต้องเป็นสิ่งที่ดีที่สุด
                    ลูกค้าจะได้สัมผัสกับความคุ้มค่าและตอบโจทย์การเลือกที่อยู่อาศัยที่เปรียบเสมือนที่ที่จะเติมพลังกาย
                    พลังใจในการใช้ชีวิต และบ้านในแบบของแกรนด์ ทรีนีตี้
                    จะเป็นอีกหนึ่งทางเลือกคุณภาพสำหรับลูกค้าที่จะมอบความไว้วางใจให้เราดูแล” นายดิษย์ฐา จิราศิริวรภัทร
                    กล่าวปิดท้าย

                </p>
            </div>

            <div class="col-12 py-4">
                <span class="cap-view">
                    <fa-icon [icon]="faCalendar"></fa-icon> 22 เม.ย. 2564
                </span> <span class="cap-view ">
                    <fa-icon [icon]="faEye"></fa-icon> 1,670
                </span>
            </div>

            <div class="col-12">
                <h5>Tag :
                    <span class="badge badge-bg-secondary mx-1">#News</span>
                    <span class="badge badge-bg-secondary mx-1">#Property</span>
                </h5>
            </div>
        </div>
    </div>

    <app-relatednews></app-relatednews>

    <div class="container">
        <div class="row">
            <!-- <app-ads></app-ads> -->
        </div>
    </div>

</div>