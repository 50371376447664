<div class="container py-4 mb-5">
    <h1 class="lead lead-block text-center my-3 pt-xl-4 pb-3">
        บทความล่าสุด
        <hr class="line-cap mt-2" />
    </h1>

    <div class="row">
        <div class="col-12">
            <div class="card-group">
                <div class="card card-news">
                    <div class="card-inner-top position-relative">
                        <div class="content">
                            <img src="./assets/images/overlay.png" alt="" class="card-img-overlay2" />
                            <img src="./assets/images/mock_news/news-01.png" class="card-img-top" alt="..." />
                        </div>
                        <div class="pb-3 ps-3 text-overlay mx-auto position-absolute fixed-bottom">
                            <div class="line">
                                <img width="20" class="mt-1" src="./assets/images/Line-left.png" alt="" />
                            </div>
                            <div class="over-txt">
                                <h4 class="m-0 text-white text-overlay lead-shadow">
                                    แกรนด์ ทรีนีตี้
                                </h4>
                                <h5 class="m-0 slide-caption text-overlay lead-shadow text-neon">
                                    อสังหาฯ พร้อมเปิดอีก 2 โครงการ
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <small>#Property </small>
                        <h5 class="card-title mb-xl-3 mb-md-1 mt-2">
                            งัดกลยุทธ์สู้ศึกอสังหาฯ ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2
                            โครงการ
                        </h5>
                        <p class="card-text">
                            นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์
                            ทรีนีตี้ ดีเวลลอปเม้นท์ จำกัด กล่าวว่า
                            “ตลาดอสังหาริมทรัพย์ยังคงได้...
                        </p>
                    </div>
                    <!-- <div class="card-footer">
            <small class="text-muted">Last updated 3 mins ago</small>
          </div> -->
                </div>
                <div class="card card-news">
                    <div class="card-inner-top position-relative">
                        <div class="content">
                            <img src="./assets/images/overlay.png" alt="" class="card-img-overlay2" />
                            <img src="./assets/images/mock_news/news-02.png" class="card-img-top" alt="..." />
                        </div>
                        <div class="pb-3 ps-3 text-overlay mx-auto position-absolute fixed-bottom">
                            <div class="line">
                                <img width="20" class="mt-1" src="./assets/images/Line-left.png" alt="" />
                            </div>
                            <div class="over-txt">
                                <h4 class="m-0 text-white text-overlay lead-shadow">
                                    นุ่น วรนุช สุดเจิดจรัส
                                </h4>
                                <h5 class="m-0 slide-caption text-overlay lead-shadow text-neon">
                                    อวดลุคชุดไทยมูลค่าสูงถึง 7 หลัก
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <small>#Social</small>
                        <h5 class="card-title mb-xl-3 mb-md-1 mt-2">
                            สวยตราตรึง! นุ่น วรนุช สุดเจิดจรัส ขึ้นปกนิตยสารแพรวเวดดิ้ง
                        </h5>
                        <p class="card-text">
                            ถ้าถามว่าชุดไทยดาราคนไหนใส่แล้วดูสวยสง่างามมากที่สุด
                            หลายคนคงพุ่งเป้าไปที่สาว นุ่น วรนุช ภิรมย์ภักดี อย่างแน่นอน
                            ล่าสุดสาวนุ่นยอม...
                        </p>
                    </div>
                    <!-- <div class="card-footer">
            <small class="text-muted">Last updated 3 mins ago</small>
          </div> -->
                </div>
                <div class="card card-news">
                    <div class="card-inner-top position-relative">
                        <div class="content">
                            <img src="./assets/images/overlay.png" alt="" class="card-img-overlay2" />
                            <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="..." />
                        </div>
                        <div class="pb-3 ps-3 text-overlay mx-auto position-absolute fixed-bottom">
                            <div class="line">
                                <img width="20" class="mt-1" src="./assets/images/Line-left.png" alt="" />
                            </div>
                            <div class="over-txt">
                                <h4 class="m-0 text-white text-overlay lead-shadow">
                                    กิ๊บซี่ วนิดา งดเซ็กซี่
                                </h4>
                                <h5 class="m-0 slide-caption text-overlay lead-shadow text-neon">
                                    อวดลุคใหม่ ราวกับเจ้าหญิงในเทพนิยาย

                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-xl-3 mb-md-1 mt-2">
                            กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">
                            ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง
                            เพราะล่าสุด...
                        </p>
                    </div>
                    <!-- <div class="card-footer">
            <small class="text-muted">Last updated 3 mins ago</small>
          </div> -->
                </div>
            </div>
        </div>
    </div>
</div>