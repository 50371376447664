import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lasted-blog',
  templateUrl: './lasted-blog.component.html',
  styleUrls: ['./lasted-blog.component.scss']
})
export class LastedBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
