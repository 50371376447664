import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  jobs: any;
  constructor(
    private router: Router,
    private db: AngularFirestore
  ) { }

  ngOnInit(): void {
  }
  gosearch(): void {
    this.router.navigate(['/home/search']);
  }

}
