<div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
            aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
            <img src="./assets/images/Hero/cate/mock-02.png" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block p-xl-5 p-md-4">
                <img src="./assets/images/logobrand/na.png" class="d-block" alt="..." height="70">
                <p class="text-left pt-2 m-0 slide-title">ร้าน ณ บวร คาเฟ่  </p>
                <p class="slide-caption">ร้านอาหารไทยเปิดใหม่ติดถนนพระสุเมรุ หน้าวัดบวรนิเวศวิหาร ในเครือเดียวกันกับ ณ บวร คาเฟ่ ที่ตั้งอยู่ใกล้กัน... </p>
                <button type="button" class="btn btn-outline-secondary btn-slide-more">อ่านต่อทั้งหมด</button>
            </div>
        </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>

<div class="row">
    <h3 class="lead lead-block text-center my-3 pt-xl-4 pb-3">ไลฟ์สไตล์
        <hr class="line-cap mt-2" />
    </h3>
</div>
<div class="row">
    <div class="col-md-8 col-12 d-md-flex justify-content-center mx-auto text-center px-0">
        <div class="floafloat-left px-0 px-md-2 py-2">
            <span class="badge badge-bg-primary">#LifestyleAll</span>
        </div>
        <div class="floafloat-left px-0 px-md-2 py-2">
            <span class="badge badge-bg-secondary">#Food</span>
        </div>
        <div class="floafloat-left px-0 px-md-2 py-2">
            <span class="badge badge-bg-secondary">#Travel </span>
        </div>
        <div class="floafloat-left px-0 px-md-2 py-2">
            <span class="badge badge-bg-secondary">#Fashion </span>
        </div>
        <div class="floafloat-left px-0 px-md-2 py-2">
            <span class="badge badge-bg-secondary">#Promotion</span>
        </div>
    </div>
</div>

<div class="container-fuild drimp-light-xs my-5">
    <div class="container py-5 px-md-5 px-3">
        <div class="row">
            <div class="card-group mt-3 p-md-0">
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-01.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Property </small>
                        <h5 class="card-title mb-3 mt-2">งัดกลยุทธ์สู้ศึกอสังหาฯ
                            ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครงการ
                        </h5>
                        <p class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                            ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-02.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Social</small>
                        <h5 class="card-title mb-3 mt-2">สวยตราตรึง! นุ่น วรนุช สุดเจิดจรัส ขึ้นปกนิตยสารแพรวเวดดิ้ง
                        </h5>
                        <p class="card-text">ถ้าถามว่าชุดไทยดาราคนไหนใส่แล้วดูสวยสง่างามมากที่สุด
                            หลายคนคงพุ่งเป้าไปที่สาว นุ่น วรนุช ภิรมย์ภักดี อย่างแน่นอน ล่าสุดสาวนุ่นยอม...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>

                <div class="card card-news">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-group mt-3 p-md-0">
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-01.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Property </small>
                        <h5 class="card-title mb-3 mt-2">งัดกลยุทธ์สู้ศึกอสังหาฯ
                            ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครงการ
                        </h5>
                        <p class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                            ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-02.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Social</small>
                        <h5 class="card-title mb-3 mt-2">สวยตราตรึง! นุ่น วรนุช สุดเจิดจรัส ขึ้นปกนิตยสารแพรวเวดดิ้ง
                        </h5>
                        <p class="card-text">ถ้าถามว่าชุดไทยดาราคนไหนใส่แล้วดูสวยสง่างามมากที่สุด
                            หลายคนคงพุ่งเป้าไปที่สาว นุ่น วรนุช ภิรมย์ภักดี อย่างแน่นอน ล่าสุดสาวนุ่นยอม...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>

                <div class="card card-news">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-group mt-3 p-md-0">
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-01.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Property </small>
                        <h5 class="card-title mb-3 mt-2">งัดกลยุทธ์สู้ศึกอสังหาฯ
                            ตั้งเป้ารายได้โต 20% พร้อมเปิดอีก 2 โครงการ
                        </h5>
                        <p class="card-text">นายดิษย์ฐา จิราศิริวรภัทร ประธานเจ้าหน้าที่บริหาร บริษัท แกรนด์ ทรีนีตี้
                            ดีเวลลอปเม้นท์ จำกัด กล่าวว่า “ตลาดอสังหาริมทรัพย์ยังคงได้...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-02.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Social</small>
                        <h5 class="card-title mb-3 mt-2">สวยตราตรึง! นุ่น วรนุช สุดเจิดจรัส ขึ้นปกนิตยสารแพรวเวดดิ้ง
                        </h5>
                        <p class="card-text">ถ้าถามว่าชุดไทยดาราคนไหนใส่แล้วดูสวยสง่างามมากที่สุด
                            หลายคนคงพุ่งเป้าไปที่สาว นุ่น วรนุช ภิรมย์ภักดี อย่างแน่นอน ล่าสุดสาวนุ่นยอม...</p>
                    </div>

                </div>
                <div class="card card-news me-2">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>

                <div class="card card-news">
                    <img src="./assets/images/mock_news/news-03.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <small>#Trends</small>
                        <h5 class="card-title mb-3 mt-2">กิ๊บซี่ วนิดา งดเซ็กซี่ปรอทแตก อวดลุคใหม่
                            สวยหวานงดงามราวกับเจ้าหญิงในเทพนิยาย
                        </h5>
                        <p class="card-text">ภาพสาวตัวเล็กสุดเซ็กซี่พริกขี้หนูยกสวนก็ลอยมาแต่ไกล
                            แต่วันนี้พิสูจน์แล้วว่าเธอมีหลายมุมมองที่โดดเด่นมากมายในตัวเอง เพราะล่าสุด...</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
  <!-- <app-ads></app-ads> -->
    </div>
</div>