import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdsComponent } from './ads/ads.component';
import { RelatednewsComponent } from './relatednews/relatednews.component';
import { SearchComponent } from './search/search.component';
import { LastedBlogComponent } from './home-elements/lasted-blog/lasted-blog.component';
import { PopularBlogComponent } from './home-elements/popular-blog/popular-blog.component';
import { NewsBlogComponent } from './home-elements/news-blog/news-blog.component';
import { IdeasBlogComponent } from './home-elements/ideas-blog/ideas-blog.component';
import { HeroSlideComponent } from './home-elements/hero-slide/hero-slide.component';


@NgModule({
  declarations: [
    AdsComponent,
    RelatednewsComponent,
    SearchComponent,
    LastedBlogComponent,
    PopularBlogComponent,
    NewsBlogComponent,
    IdeasBlogComponent,
    HeroSlideComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AdsComponent,
    RelatednewsComponent,
    SearchComponent,
    LastedBlogComponent,
    PopularBlogComponent,
    NewsBlogComponent,
    IdeasBlogComponent,
    HeroSlideComponent
  ],
})
export class SharedModule { }
