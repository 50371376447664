import { Component, OnInit } from '@angular/core';
import { faCalendar, faEye, faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  faHome = faHome;
  faEye = faEye;
  faCalendar = faCalendar;
  constructor() { }

  ngOnInit(): void {
  }

}
